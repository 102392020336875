body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*============== MY CUSTOM CSS JONAYED ISLAM =========================*/
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&family=Roboto:wght@400;500&display=swap');
/* font-family: 'Oswald', sans-serif;
font-family: 'Roboto', sans-serif; */

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
}
.back-to-top{
  opacity: 0;
  transition:all .5s;
  z-index:10000;
  position:fixed;
  bottom:15px;
  right:15px;
  border-radius: 0;
  padding: 0px 5px;
  padding-bottom: 2px;
}
.back-to-top svg {
  font-size: 30px;
  line-height: 30px;
}
.top-header-wrapper {
  padding-top: 17px;
  padding-bottom: 12px;
}
.logo-wrapper {
  text-align: center;
}
.logo-right-wrapper {
  text-align: right;
}
.top-contact-icon-wrapper {
  margin-top: 25px;
}
.top-contact-icon-wrapper a {
  color: black;
  text-decoration: none;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
}
.logo-right-wrapper .btn {
  margin-top: 10px;
  line-height: 20px;
  padding: 9.5px 16px;
  font-size: 14px;
  font-weight: 400;
  background-color: #FEAA36;
  color: #FFFFFF;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0;
}
.logo-right-wrapper .btn:hover {
  background-color: #fff;
  color: #FEAA36;
  border: 1px solid #FEAA36;
}
.bg-light {
  background-color: #fff !important;
}
.nav-link {
  color: #000;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  padding: 10px 13px !important;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: underline;
  color: #000;
}
.nav-link.active{
  text-decoration: underline;
}
.fixedTop {
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 20%);
}
/* HOME SLIDER STYLE */
.home-slider-image-wrapper {
  padding: 0;
}
.home-slider-image {
  background: url(../public/assets/home-slider-image.jpg) center;
  background-size: cover;
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
}
.home-slider-image-text-wrapper {
  top: 80%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  position: absolute;
}
.home-slider-image-text h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 35px;
  font-weight: 400;
  line-height: 45px;
}
.home-slider-image-text {
  max-width: 550px;
  margin: auto;
}
.slider-btn-wrapper .btn {
  line-height: 20px;
  padding: 9.5px 16px;
  font-size: 14px;
  font-weight: 400;
  background-color: #FEAA36;
  color: #FFFFFF;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0;
}
.slider-btn-wrapper .btn:hover {
  background-color: #fff;
  color: #FEAA36;
  border: 1px solid #FEAA36;
}
/*=============== HOME ABOUT SECTION STYLE ==================*/
.home-about-section {
  padding: 80px 0;
  width: 100%;
}
.home-about-text {
  text-align: center;
}
.home-about-text h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 400;
  margin: 0;
}
.home-about-text h3 {
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  line-height: 20px;
}
.home-about-text p {
  font-size: 16px;
}
.btn-home-link {
  line-height: 20px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 400;
  background-color: #FEAA36;
  color: #FFFFFF;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
}
.btn-home-link:hover {
  background-color: #fff;
  color: #FEAA36;
  border: 1px solid #FEAA36;
}
.home-about-row {
  padding-top: 70px;
}
.home-about-row-text {
  margin-top: 125px;
}
.home-about-row-text h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 5px;
}
.home-about-row-text h2 {
  font-size: 35px;
  line-height: 35px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
}
.home-about-row-text p {
  width: 78.5%;
  padding: 30px 0;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  text-align: justify;
}
.home-about-row-image {
  padding-left: 60px;
}
/*HOME SLIDER SECTION */
.home-carousel-wrapper {
  padding: 80px 0;
}
.home-carousel-text {
  text-align: center;
  margin-bottom: 20px;
}
.home-carousel-text h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
}
.carousel-indicators {
  bottom: -50px;
}
.home-ul-list {
  margin: 0;
  padding: 0;
}
.home-ul-list li {
  list-style: none;
  margin-bottom: 6px;
  font-weight: 400;
  padding-left: 40px;
  color: #222;
}
.home-ul-list li svg {
  color: #FEAA36;
  margin-right: 10px;
  font-size: 25px;
}
.carousel-indicators [data-bs-target] {
  background-color: #FEAA36;
}
/*================== HOME SERVICE SECTION STYLE ================================*/
.home-service-section {
  width: 100%;
  position: relative;
}
.home-service-text {
  text-align: center;
  padding-bottom: 110px;
}
.home-service-text h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 5px;
}
.home-service-text h3 {
  font-size: 35px;
  line-height: 25px;
  font-weight: 400;
  text-transform: capitalize;
}
.home-service-bg-image {
  background: url(../public/assets/service-section-bg.jpg) center;
  background-size: cover;
  width: 100%;
  /* height: 90vh; */
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  background-position: bottom;
}
.home-service-single {
  margin-bottom: 160px;
  display: inline-block;
  margin-top: -90px;
}
.home-service-single .btn-service-link {
  position: relative;
  text-decoration: none;
}
.home-service-single .btn-service-link img {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 18px -6px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 18px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 18px -6px rgb(0 0 0 / 75%);
}
.description {
  max-width: 275px;
  margin: auto;
  position: relative;
  margin-top: -161px;
  top: -14px;
  padding: 0 40px;
  padding-bottom: 5px;
}
.description h2.section-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  min-height: 56px;
  line-height: 27px;
  font-weight: 500;
  color: #000;
}
.ser-line {
  border: 1px solid #007167;
  border-color: #007167;
  border-width: 1px;
  width: 60px;
  margin: 10px auto 30px;
}
.btn-service-btn {
  line-height: 20px;
  padding: 9px 20px;
  font-size: 14px;
  font-weight: 400;
  background-color: #FEAA36;
  color: #FFFFFF;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
}
.btn-service-btn:hover {
  background-color: #fff;
  color: #FEAA36;
  border: 1px solid #FEAA36;
}
.home-service-single:hover .btn-service-link img {
  content: url(../public/assets/mortgage-refinance-bg.png);
}
.home-purchase:hover .btn-service-link img {
  content: url(../public/assets/home-purchase-bg.png);
}
.self-employed:hover .btn-service-link img {
  content: url(../public/assets/self-employed-bg.png);
}
.home-equity:hover .btn-service-link img {
  content: url(../public/assets/home-equity-line-of-credit-bg.png);
}
.investment-property:hover .btn-service-link img {
  content: url(../public/assets/investment-property-bg.png);
}
.reverse-mortgage:hover .btn-service-link img {
  content: url(../public/assets/reverse-mortgage-bg.png);
}
.home-service-single:hover .description h2.section-subtitle {
  color: #fff;
}
.reverse-mortgage, .investment-property, .home-equity {
  margin-bottom: 130px;
}
/*=================== Home Contact Section style ================*/
.home-contact-section {
  padding: 80px 0;
}
.home-contact-text {
  margin-top: 140px;
  padding-left: 60px;
}
.home-contact-text h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 5px;
}
.home-contact-text h2 {
  font-size: 35px;
  line-height: 35px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
}
.home-contact-text p {
  width: 93.5%;
  padding: 30px 0;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  text-align: justify;
}
/*=================== Home Blog Section style ================*/
.home-blog-image-wrapper {
  background: url(../public/assets/home-blog-bg.jpg) center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.home-blog-image-wrapper a {
  text-decoration: none;
}
.home-blog-image {
  padding: 25px 25px 25px 45px;
  height: 360px;
  background: #feaa36;
  margin-left: 100px;
  margin-top: 130px;
  margin-bottom: 40px;
  position: relative;
}
.home-blog-link {
  line-height: 20px;
  padding: 9px 35px;
  font-size: 14px;
  font-weight: 400;
  background-color: #000;
  color: #FFFFFF;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
}
.home-blog-link:hover {
  background-color: #fff;
  color: #FEAA36;
  border: 1px solid #FEAA36;
}
.home-blog-image-text {
  padding-top: 80px;
  padding-left: 45px;
}
.home-blog-image-text h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 5px;
  color: #000;
}
.home-blog-image-text h2 {
  font-size: 35px;
  line-height: 35px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 50px;
  color: #000;
}
.home-blog-image-text .ser-line {
  margin: unset;
  margin-top: 25px;
  margin-bottom: 15px;
}
.home-blog-image-text h3:after {
  position: absolute;
  right: 40px;
  top: 30px;
  content: url(../public/assets/blog-icon.png);
}

/*================== footer style =====================*/
.footer-wrapper {
  color: #FFFFFF;
  background: #000000;
  padding: 25px 0 15px;
  width: 100%;
}
.footer-wrapper h3 {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}
.footer-page-link a {
  display: inline-block;
  width: 100%;
  color: #fff;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}
.footer-social-icon {
  margin: 0;
  padding: 0;
}
.footer-social-icon a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
}
.footer-social-icon a svg {
  height: 45px;
  width: 45px;
}
.footer-social-icon a:hover {
  opacity: 0.8;
}
.footer-subscribe .btn {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 0;
  font-size: 15px;
  padding: 10px 30px;
}
.footer-subscribe .btn:hover {
  background: #fff;
  color: #000;
}
.footer-address a, .footer-address p {
  display: inline-block;
  width: 100%;
  color: #fff;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}
.footer-address-link {
  margin-bottom: 10px;
  max-width: 200px;
}
.footer-wrapper-buttom {
  width: 100%;
  text-align: center;
  border-top: 1px solid #333;
  margin-top: 15px;
  padding-top: 15px;
  font-size: 14px;
}
.footer-wrapper-buttom a {
  color: #fff;
  text-decoration: none;
}
.footer-wrapper-buttom-text p {
  margin: 0;
}
/*=================== About Page style ================*/
.about-page-section {
  width: 100%;
  text-align: center;
  padding-top: 60px;
}
.about-page-row {
  margin-bottom: -30px;
}
.about-page-text {
  text-align: center;
  margin-bottom: 50px;
}
.about-page-text h1 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 400;
  margin: 0;
}
.about-page-text h3 {
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  line-height: 20px
}
.about-page-row-text {
  text-align: left;
  width: 88%;
  margin-top: 150px;
}
.about-page-row-text p {
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 15px;
  font-size: 16px;
  margin: 0;
  text-align: justify;
}
.about-page-row-image {
  margin-left: 50px;
}
.contact-btn-section {
  text-align: center;
  padding-bottom: 70px;
}
/*=============== Mortgage Refinance SECTION STYLE ==================*/
.refinance-intro-wrapper {
  background: url(../public/assets/refinance-wrapper-bg.jpg) center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.refinance-intro-top {
  padding-top: 120px;
  padding-bottom: 300px;
}
.refinance-intro-top {
  padding-left: 50px;
}
.refinance-intro-top h1 {
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
}
.refinance-intro-top h2 {
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
}
.refinance-middle-wrapper {
  padding: 80px 0;
}
.refinance-middle-image {
  padding-left: 80px;
}
.refinance-middle-text {
  padding-top: 60px;
  width: 80%;
}
.refinance-middle-text h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 400;
}
.refinance-middle-text p {
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 15px;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  text-align: justify;
}
.mortgage-refinancing-wrapper {
  background: url(../public/assets/mortgage-refinancing-bg.jpg) center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 60px 0;
}
.mortgage-refinancing-left {
  position: relative;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  padding-left: 90px;
  padding-right: 50px;
}
.mortgage-refinancing-left h2 {
  font-size: 32px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: 1.3;
}
.mortgage-refinancing-left:after {
  content: '';
  position: absolute;
  background: #feaa36ad;
  width: 0.5px;
  height: 100%;
  top: 0;
  right: 0;
}
.mortgage-refinancing-left p {
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 15px;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}
.mortgage-refinancing-right {
  position: relative;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 40px;
}
.mortgage-refinancing-right h2 {
  font-size: 32px;
  line-height: 25px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 20px;
  line-height: 1.3;
}
.mortgage-refinancing-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
}
.mortgage-refinancing-right ul li {
  list-style: none;
  line-height: 30px;
}
.mortgage-refinancing-right ul li svg {
  color: #FEAA36;
  margin-right: 15px;
  font-size: 22px;
}
.mortgage-experts-wrapper {
  background: url(../public/assets/mortgage-experts-bg.jpg) center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 80px 0;
  text-align: center;
}
.mortgage-experts-text h2 {
  font-size: 35px;
  line-height: 25px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.mortgage-experts-text p {
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 15px;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}
.mortgage-experts-text h3 {
  font-size: 16px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #000;
}
/*=============== Contact Page STYLE ==================*/
.contact-page-section {
  padding-top: 50px;
  padding-bottom: 40px;
}
.contact-page-icon-list {
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-box-shadow: 0 20px 60px 0 rgb(40 93 251 / 16%);
  box-shadow: 0 20px 60px 0 rgb(97 99 106 / 16%);
  min-height: 295px;
  padding-top: 25px;
}
.contact-page-icon {
  border-right: 5px dashed #FEAA36;
  border-bottom: 5px solid #FEAA36;
  display: inline-block;
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 50%;
}
.contact-page-icon svg {
 color: #FEAA36;
 font-size: 50px;
}
.contact-page-icon-list h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: center !important;
  text-transform: uppercase;
}
.contact-page-icon-list p {
  text-align: center;
  font-weight: 400;
  line-height: 26px;
  font-size: 16px;
  max-width: 80%;
  margin: auto;
}
.contact-page-icon-list p a {
  color: #FEAA36;
  text-decoration: none;
}
.contact-page-container {
  margin-bottom: 70px;
}
.btn-contact-page {
  line-height: 20px;
  padding: 9px 35px;
  font-size: 14px;
  font-weight: 400;
  background-color: #FEAA36;
  color: #FFFFFF;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 0;
  text-transform: uppercase;
  margin-top: 10px;
}
.btn-contact-page:hover {
  background-color: #fff;
  color: #FEAA36;
  border: 1px solid #FEAA36;
}
.contact-page-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}
.red-icon {
  color: red;
}
.contact-page-form .form-label {
  color: #000;
  margin-bottom: 5px;
}
.contact-right-map {
  margin-right: 15px;
}
.contact-page-form {
  margin-left: 15px;
}
/* Home Purchase Page style */
.home-purchase-page-section {
  padding-top: 60px;
}
.home-purchase-page-image {
  margin-left: 80px;
}
.home-purchase-page-text h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 400;
  margin: 0;
  margin-top: 25px;
}
.home-purchase-page-text p {
  font-weight: 400;
  line-height: 26px;
  padding: 0 0 15px;
  font-size: 16px;
  margin: 0;
  width: 85%;
  text-align: justify;
}
.home-purchase-page-row {
  margin-bottom: 60px;
}
.home-purchase-middle-text p {
  line-height: 30px;
}
.self-mortgage-page-text {
  margin-top: 25px;
  display: inline-block;
}
.self-mortgage-mortgage-left-text {
  padding-top: 90px;
  padding-bottom: 90px;
  display: inline-block;
}
.mortgage-refinancing-right p {
  margin-top: 15px;
}
/*Blog Page Style */
.blog-intro-wrapper {
  background: url(../public/assets/blog-intro-bg.jpg) center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 210px 0;
  text-align: center;
}
.blog-intortext {
  text-align: center;
}
.blog-intor-text h1 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 400;
  margin: 0;
}
.blog-body-wrapper {
  padding: 70px 0;
}
.blog-left-side {
  padding: 25px;
  box-shadow: 0 0 10px rgba(21, 21, 21, 0.2);
  margin-right: 10px;
}
.blog-right-side {
  padding: 25px;
  box-shadow: 0 0 10px rgba(21, 21, 21, 0.2);
}
.blog-right-side-title h2 {
  position: relative;
  font-size: 25px;
  font-weight: 700;
  color: #222222;
  padding: 0 0 20px;
  margin: 0 0 20px;
  line-height: 1;
  text-transform: uppercase;
}
.blog-right-side-title h2:hover {
  color: #FEAA36;
}
.blog-right-side-title h2:before {
  content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-index: 1;
}
.blog-right-side-title h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 55px;
  height: 3px;
  background-color: #FEAA36;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  z-index: 2
}
.blog-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog-ul li {
  font-size: 16px;
  margin-bottom: 10px;
}
.blog-ul li a {
  color: #222222;
  display: inline-block;
  position: relative;
  padding-left: 20px;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  transition: 350ms;
  position: relative;
  font-weight: 500;
  text-decoration: none;
}
.blog-ul li a:hover {
  color: #FEAA36;
}
.blog-ul li a:before {
  background: #FEAA36;
  position: absolute;
  height: 10px;
  width: 10px;
  content: '';
  left: 0;
  top: 8px;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.blog-left-side-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-left-side-ul li {
  list-style: none;
  display: inline-block;
}
.blog-left-side-ul li a {
  padding: 5px 10px;
    background-color: #edefef;
    border: 1px solid #ecf0f3;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 5px;
    line-height: 1.7;
    display: inline-block;
    color: #000;
    text-decoration: none;
}
.blog-left-side-ul li a:hover {
  background: #feaa36;
  color: #fff;
}
.blog-image {
  margin: 15px 0;
}









.navbar-brand {display: none;}
@media only screen and (max-width: 767px) {
.navbar-brand {display: block;padding: 0;margin: 0;}
.navbar-brand img {max-width: 250px;}

}